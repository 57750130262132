import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StockGainer from "../components/StockGainer";
import ChartTwo from "../components/ChartTwo";
import ChartExpert from "../components/ChartExpert";
import ChartBandLight from "../components/ChartBandLight";

export const CurrencyPageTemplate = ({ image, title, heading, subheading, mainpitch, description, intro }) => (
  <div>
    <Helmet>
      <title>Swiftfox | Currency</title>
    </Helmet>
    <div className="hero-section home-hero">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h1 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Currency Transfers.<br /><span>Undeniably Swift</span>
                </motion.h1>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Here to help you navigate international money transfers, mitigate risk and maximise returns. We’re only ever a phone call away.
                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button" data-srcoll-to to="/#about">
                    Discover more
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item hovering">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.0, ease: "easeInOut" }} className="hero-chart pushmeright chart-perspective">
                  <StockGainer />
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
      <div className="hero-banner">
        <InView threshold={0.1}>
          {({ ref, inView }) => (
            <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.25, ease: "easeInOut" }} className="hero-banner-content">
                <div className="section-item">
                  <p>We are trusted by over 200 countries globally and serve 40 different currencies.</p>
                </div>
                <div className="section-item">
                  {/* <div className="banner-images">
                    <img alt="trust pilot" src="../img/trust-pilot.svg"/>
                  </div> */}
                </div>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="callout-section callout-bg dark-text" id="about">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item white-text">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Your expert currency exchange broker
                </motion.h2>
              )}
            </InView>
          </div>
          <div className="section-item">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.p ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  There are countless ways to transfer money abroad. The most important consideration is the timing and mode of a transaction. When you exchange currencies with Swiftfox, we help you find that sweet spot, reducing your risk and maximising returns.
                </motion.p>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="hero-section dark-bg">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  <span>International payments, made easy.</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Swiftfox is here to help you understand the market and avoid making mistakes.
<br/><br/>
We understand the disruption and volatility of the FX markets and employ strategies that mitigate risk and maximise returns.

                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button" data-srcoll-to to="/open-an-account">
                  Check my rates
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 1, delay: 1.0, ease: "easeInOut" }} className="module-stack">
                  <div className="chart">
                    <ChartExpert />
                  </div>
                  <div className="bullet-container">
                    <div className="bullet">
                      <img alt="Fees" src="../img/cash.svg" />
                      <p>Fees &<br />Charges</p>
                    </div>
                    <div className="bullet">
                      <img alt="Tax" src="../img/tax.svg" />
                      <p>Tax<br />Clearance</p>
                    </div>
                    <div className="bullet">
                      <img alt="FX Dealers" src="../img/fx.svg" />
                      <p> Access of<br />FX Dealers</p>
                    </div>
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
      <div className="hero-banner-light">
        <InView threshold={0.1}>
          {({ ref, inView }) => (
            <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.25, ease: "easeInOut" }} className="hero-banner-ticker">
                <ChartBandLight />
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="callout-section orange-bg white-text">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} className="module-stack">
                  <div className="bullet-container">
                    <div className="bullet">
                      <img alt="No Hodden Fees" src="../img/fee.svg" />
                      <p>No Hidden Fees</p>
                    </div>
                    <div className="bullet">
                      <img alt="No Contracts" src="../img/contracts.svg" />
                      <p>No Contracts</p>
                    </div>
                    <div className="bullet">
                      <img alt="No Payment Fees" src="../img/payment.svg" />
                      <p>No Payment Fees</p>
                    </div>
                    <div className="bullet">
                      <img alt="24/7 Support" src="../img/sup.svg" />
                      <p>24/7<br />Support</p>
                    </div>
                    <div className="bullet">
                      <img alt="Accoutn Managers" src="../img/manager.svg" />
                      <p>Dedicated Acc. Managers</p>
                    </div>
                    <div className="bullet">
                      <img alt="Fast & Easy Payments" src="../img/fast.svg" />
                      <p>Fast & Easy Int. Payments</p>
                    </div>
                    <div className="bullet">
                      <img alt="Pre-Saved Beneficiaries" src="../img/saved.svg" />
                      <p>Pre-Saved Beneficiaries</p>
                    </div>
                    <div className="bullet">
                      <img alt="Trade Status & History" src="../img/history.svg" />
                      <p>Trade Status & History</p>
                    </div>
                    <div className="bullet">
                      <img alt="FCA" src="../img/fca.svg" />
                      <p>FCA<br />Authorised</p>
                    </div>
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  What Swiftfox can<br/>do for you
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                 We’re here to save you time and money. We work with you directly and can answer your questions and calls 24 hours a day, seven days a week.
                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button" to="/faq">
                    Get in touch
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="callout-section dark-bg">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  <span>Check your real-time conversion rate</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Follow live currency rates at a glance. These tables show real-time bid and ask rates for all currency pairs traded at Swiftfox.
                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button" data-srcoll-to to="/#about">
                  Make a transfer
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.0, ease: "easeInOut" }} className="hero-chart-callout pushmeright sizefixer">
                  <ChartTwo />
                </motion.div>
              )}
            </InView>
          </div>
        </div> 
      </div>
    </div>
    <div className="callout-section personal-hero dark-text" id="get-in-touch">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }} className="button-container stacker">
                  <div className="blue-block">
                    <h4>Business Transfers</h4>
                    <p>Swiftfox Provide The Service And The Advice That Will Protect The Profitability Of Your International Transactions.</p>
                    <Link to="business" className="main-button-alt">
                      Apply for a Business Account
                    </Link>
                  </div>
                  <div className="grey-block">
                    <h4>Personal Transfers</h4>
                    <p>Our customers typically save up to 4% when converting currency compared to using a bank.</p>
                    <Link to="personal" className="main-button-alt">
                      Apply for a Personal Account
                    </Link>
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Get in touch
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }}>
                  To speak with one of our expert account managers about how we can help you.
                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.0, ease: "easeInOut" }}  className="button-container">
                  <a className="alt-button" href="tel:+44 203 751 7522">
                    <img alt="Phone" src="../img/PhoneAlt.svg" />
                  </a>
                  <a className="alt-button" href="mailto:support@swiftfox.co.uk">
                    <img alt="Email" src="../img/email.svg" />
                  </a>
                  <a className="alt-button" href="https://wa.me/+447788944456">
                    <img alt="Whatsapp" src="../img/whatsapp.svg" />
                  </a>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
  </div>
);

CurrencyPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const CurrencyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <CurrencyPageTemplate image={frontmatter.image} title={frontmatter.title} heading={frontmatter.heading} subheading={frontmatter.subheading} mainpitch={frontmatter.mainpitch} description={frontmatter.description} intro={frontmatter.intro} />
    </Layout>
  );
};

CurrencyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default CurrencyPage;

export const pageQuery = graphql`
  query CurrencyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
