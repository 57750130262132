import React from "react";

export default class Tabsshow extends React.PureComponent {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
    this.state = {
      stock: {
        title: "Stocks",
        width: "100%",
        height: "100%",
        showChart: true,
        locale: "en",
        plotLineColorGrowing: "rgba(255, 100, 0, 1)",
        plotLineColorFalling: "rgba(137, 189, 208, 1)",
        belowLineFillColorGrowing: "rgba(255, 100, 0, 0.12)",
        belowLineFillColorFalling: "rgba(137, 189, 208, 0.12)",
        gridLineColor: "#2A2E39",
        scaleFontColor: "rgba(255, 100, 0, 1)",
        showSymbolLogo: true,
        isTransparent: true,
        dateRange: "3M",
        symbolActiveColor: "rgba(255, 100, 0, 0.25)",
        colorTheme: "dark"
      },
      type: "Stocks"
    };
    this.currentDOMId = "";
  }
  componentDidMount() {
    const script = document.createElement("script");
    script.id = `gg${this.state.type}`;
    this.currentDOMId = `gg${this.state.type}`;
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    script.async = true;
    this._ref.current.appendChild(script);
    script.innerHTML = JSON.stringify(this.state.stock);
  }

  Changeexchange = (e) => {
    console.log(e.target.value);
    this.setState({
      type: e.target.value
    });

    // Remove the existing DOM elem
    const elem = document.getElementById(this.currentDOMId);
    elem.parentNode.removeChild(elem);

    // Create a new elem with updated values
    // so that updated iframe will load
    const script = document.createElement("script");
    script.id = `gg${this.state.type}`;
    this.currentDOMId = `gg${this.state.type}`;
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    script.async = true;
    this._ref.current.appendChild(script);
    if (e.target.value === "Forex") {
      script.innerHTML = JSON.stringify(this.state.forex);
    }
  };



  render() {
    return (
      <div className="tradingview-widget-container" ref={this._ref}>
        <div className="tradingview-widget-container__widget"> </div>
      </div>
    );
  }
}
