import React from "react";

export default class ChartExpert extends React.PureComponent {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
    this.state = {
      symbol: "FX:EURUSD",
      colorTheme: "dark",
      locale: "en",
      dateRange: "1Y",
      trendLineColor: "rgba(255, 100, 0, 1)",
      underLineColor: "rgba(255, 100, 0, 0.12)",
      underLineBottomColor: "rgba(249, 203, 156, 0)",
      isTransparent: true,
      width: "100%",
      height: "100%",
      };
    this.currentDOMId = "";
  }
  componentDidMount() {
    const script = document.createElement("script");
    script.id = `gg${this.state.type}`;
    this.currentDOMId = `gg${this.state.type}`;
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js";
    script.async = true;
    this._ref.current.appendChild(script);
    script.innerHTML = JSON.stringify(this.state);
  }
  render() {
    return (
      <div className="tradingview-widget-container" ref={this._ref}>
        <div className="tradingview-widget-container__widget"> </div>
      </div>
    );
  }
}
